import { t } from 'locale';

import { TenantSettings } from 'actions/app';

import { localeKeys } from 'enums/localeKeys';

interface GetRestrictAppMessage {
  tenantSettings: TenantSettings;
  isRegisteredFromLink: boolean;
}

export const getRestrictAppMessage = ({
  tenantSettings,
  isRegisteredFromLink
}: GetRestrictAppMessage): string => {
  const {
    active,
    disableUserRegistration,
    magicLinkSettings: { requireLinkUse } = {}
  } = tenantSettings;

  if (!active) {
    return t(localeKeys.inactiveTenantMessage);
  }

  if (requireLinkUse && !isRegisteredFromLink) {
    return t(localeKeys.tenantRestrictedUsageWithMagicLinkOnly);
  }

  if (disableUserRegistration) {
    return t(localeKeys.tenantDisabledUserRegistration);
  }

  return '';
};
