import { put, select, takeEvery } from 'redux-saga/effects';
import { clearBankSelection, getSelectedBank, selectBank } from 'slices/banks';

import { tenantSettingsSelector } from 'selectors/tenantSettingsSelector';
import { Creators as AccountCreators, Types } from 'actions/account';
import { Creators as ApiCreators } from 'api/actions';
import { fetchSaga } from 'api/fetchSaga';
import { Types as LocaleTypes } from 'locale/actions';
import { clearKeysFromStore } from 'sagas/app';
import { oldBankSelector } from 'selectors/oldBankSelector';

import { config } from 'config';

import { ApiGateway } from 'enums/apiGateway';

import { trackBankSelect } from 'utils/eventTracking';
import { isSortCodeExcluded } from 'utils/oldBank';

const { apis } = config;
const {
  OLD_BANK_LOGIN_FORM,
  OLD_BANK_LOGIN_REQUEST,
  OLD_BANK_LOGIN_MFA_STATUS,
  STOP_REG_SHIELD_REGISTRATION,
  START_REG_SHIELD_REGISTRATION
} = Types;

export function* getOldBankFormData({ payload: { selectedBank, redirectChallengeData } = {} }) {
  const selectedBankFromState = yield select(getSelectedBank);
  const { tenantSettings } = yield select(tenantSettingsSelector);

  const bank = selectedBank ? selectedBank : selectedBankFromState;

  if (bank) {
    trackBankSelect(bank);
  }

  if (
    bank &&
    !isSortCodeExcluded({
      excludedSortCodes: tenantSettings.associatedSortCodes,
      sortCode: bank.sortCode
    })
  ) {
    const { id } = bank;
    const params = {
      path: apis.bankLogins(id)
    };

    yield fetchSaga({
      actionType: OLD_BANK_LOGIN_FORM,
      fields: 'bankLogin',
      params
    });

    const oldBankData = yield select(oldBankSelector);
    const {
      bankLoginFormData: { supported, bankCode, gateway, id: bankId }
    } = oldBankData;

    if (redirectChallengeData) {
      const { widgetBaseUrl, state } = redirectChallengeData;
      const location = `${widgetBaseUrl}?state=${state}`;
      return yield put(ApiCreators.apiResponse(START_REG_SHIELD_REGISTRATION, { location }));
    }

    if (supported && gateway === ApiGateway.REGSHIELD) {
      yield put(AccountCreators.startRegShieldRegistration(bankCode, bankId));
    }
  }
}

function* clearBankFormData() {
  const keysToReset = [OLD_BANK_LOGIN_FORM, OLD_BANK_LOGIN_REQUEST, OLD_BANK_LOGIN_MFA_STATUS];
  yield clearKeysFromStore(keysToReset);
}

function* stopRegShieldRegistration() {
  const keysToReset = [START_REG_SHIELD_REGISTRATION];

  yield clearKeysFromStore(keysToReset);
}

export function* oldBankDataSaga() {
  yield takeEvery(selectBank.type, getOldBankFormData);
  yield takeEvery(clearBankSelection.type, clearBankFormData);
  yield takeEvery(STOP_REG_SHIELD_REGISTRATION, stopRegShieldRegistration);
  yield takeEvery(LocaleTypes.SET_LOCALE, getOldBankFormData);
}
