import { Route } from 'routes';

import Loadable from 'utils/Loadable';

export const magicLinkRoutes = ['/auto-login', '/register_user'];

const autoLoginPaths = magicLinkRoutes.map(route => route.slice(1));

const autoLoginRoutes: Record<string, Route> = {
  autoLogin: {
    id: 'autoLogin',
    title: 'Registration',
    path: `/(${autoLoginPaths.join('|')})`,
    isProtected: false,
    redirectOnUnauthorized: false,
    component: Loadable({
      loader: () => import('./AutoLoginContainer')
    })
  }
};

export default autoLoginRoutes;
