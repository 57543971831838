import { delay, put, takeEvery } from 'redux-saga/effects';
import { history, routes } from 'routes';
import { t } from 'locale';
import { triggerError } from 'slices/errors';

import { Types } from 'actions/account';
import { Creators as AppCreators } from 'actions/app';
import { fetchSaga } from 'api/fetchSaga';
import { clearKeysFromStore } from 'sagas/app';

import { config } from 'config';

import { httpMethods } from 'enums/httpMethods';
import { registrationStatus } from 'enums/signup';
import { localeKeys } from 'enums/localeKeys';

import { trackFpnaSuccess } from 'utils/eventTracking';

const { apis } = config;
const { REGISTER_MFA, OLD_BANK_LOGIN_MFA_STATUS } = Types;

function* getMFAStatus() {
  const params = {
    path: apis.registrationsStatus,
    method: httpMethods.GET
  };

  return yield fetchSaga({
    actionType: OLD_BANK_LOGIN_MFA_STATUS,
    fields: '',
    params
  });
}

function isStatusChangedBetweenMfaAndConsent(lastStatus, newStatus) {
  // FPNA validation flow is in two steps:
  // Step 1: `status: mfa/consent, data: URL1` (keep polling until status turns to 'mfa/consent')
  // Step 2: `status: consent/mfa, data: URL2/text` (keep polling until status turns to 'ok/invalid')

  switch (newStatus) {
    case registrationStatus.consent:
      return lastStatus === registrationStatus.mfa; //status changed from mfa -> consent
    case registrationStatus.mfa:
      return lastStatus === registrationStatus.consent; //status changed from conset -> mfa
    default:
      return false;
  }
}

function* checkMFAStatus(lastStatus) {
  const response = yield getMFAStatus();

  if (!response) return;

  const { status: newStatus } = response;
  if (isStatusChangedBetweenMfaAndConsent(lastStatus, newStatus)) {
    return;
  }

  switch (newStatus) {
    case registrationStatus.ok: {
      trackFpnaSuccess();

      return history.push(routes.signupIban.path);
    }

    case registrationStatus.mfa:
    case registrationStatus.consent:
    case registrationStatus.pending: {
      yield delay(config.registrationStatusPollingDelay);
      return yield checkMFAStatus(newStatus); // keep checking until we get the updated status
    }

    default: {
      yield clearKeysFromStore([OLD_BANK_LOGIN_MFA_STATUS]);
      yield put(
        triggerError({
          message: t(localeKeys.mfaStatusInvalidText),
          options: {
            redirected: true
          }
        })
      );
    }
  }
}

export function* registerMfa({ payload, action }) {
  const params = {
    path: apis.registrationsMfa,
    method: httpMethods.POST,
    data: {
      input: payload.mfa
    }
  };

  action.setSubmitting(false);
  yield put(AppCreators.showLoader(true));

  if (payload.mfa) {
    yield fetchSaga({
      actionType: REGISTER_MFA,
      params,
      payload
    });
  }

  // `REGISTER_MFA` doesn't return anything. Start polling for the updated status
  yield checkMFAStatus();
  yield put(AppCreators.showLoader(false));
}

export function* mfaSagas() {
  yield takeEvery(REGISTER_MFA, registerMfa);
}
