import { Bank } from 'models/Bank';

import { RegShieldScreenType } from 'enums/regshield';

const { MATOMO_ENABLED } = window.serverEnvConfig ?? {};
const isMatomoEnabled = MATOMO_ENABLED === 'true';

type FPNAScreenType =
  | 'BANK_LOGIN'
  | 'MFA_HTML'
  | 'MFA_PHOTO_TAN'
  | 'MFA_TEXT'
  | 'MFA_DECOUPLED'
  | 'MFA_UNSUPPORTED_TYPE'
  | 'ACCOUNT_SELECTION';

export const trackBankSearch = (query: string) => {
  if (isMatomoEnabled) {
    window._mtm.push({ event: 'bankSearch', bankSearchQuery: query });
  }
};

export const trackBankSelect = (bank: Bank) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'bankSelect',
      selectedBank: `${bank.shortName} - ${bank.bic} - ${bank.sortCode}`
    });
  }
};

export const trackRegShieldScreen = (screen: RegShieldScreenType) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'regShieldNavigateScreen',
      regShieldScreen: screen
    });
  }
};

export const trackRegShieldUserId = (userId: string) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'regShieldUserId',
      regShieldUserId: userId
    });
  }
};

export const trackRegShieldError = (errorCode: number, traceId: string) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'regShieldError',
      regShieldErrorCode: errorCode,
      regShieldTraceId: traceId
    });
  }
};

export const trackRegShieldSuccess = () => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'regShieldSuccess'
    });
  }
};

export const trackRegShieldRedirect = (redirectUrl: string) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'regShieldRedirect',
      regShieldRedirectUrl: redirectUrl
    });
  }
};

export const trackFpnaScreen = (screen: FPNAScreenType) => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'fpnaScreen',
      fpnaScreen: screen
    });
  }
};

export const trackFpnaSuccess = () => {
  if (isMatomoEnabled) {
    window._mtm.push({
      event: 'fpnaSuccess'
    });
  }
};
